import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { AnimatePresence, motion } from "framer-motion";
import { type FC, type ReactNode } from "react";
import { BsArrowRight } from "react-icons/bs";
import { ImSpinner3 } from "react-icons/im";

import cn from "../../util/cn.js";
import Keyboard from "../Keyboard/Keyboard.js";
import Tooltip from "../Tooltip/Tooltip.js";
import type { ButtonWrapperProps } from "./ButtonWrapper.js";
import ButtonWrapper from "./ButtonWrapper.js";

export const ButtonStyles = cva(
  // Don't break test
  "relative inline-flex items-center justify-center transition-all duration-75 group whitespace-nowrap  overflow-hidden  whitespace-nowrap",
  {
    variants: {
      intent: {
        primary: "border border-accent text-accent-inverse shadow-sm",
        secondary: "",
        link: "",
        icon: "",
      },
      size: {
        subtle: "text-sm text-secondary",
        xs: "px-2 py-1 rounded text-xs",
        sm: "px-3 py-1 rounded text-sm",
        md: "px-4 py-2 rounded-md font-medium",
        lg: "px-6 py-3 rounded-lg text-lg font-medium",
      },
      texture: {
        true: "",
      },
      block: {
        true: "w-full",
      },
      mobileBlock: {
        true: "w-full md:w-auto",
      },
      loading: {
        true: "cursor-wait",
      },
      disabled: {
        true: "opacity-50 cursor-not-allowed",
        false: "",
      },
    },
    defaultVariants: {
      intent: "secondary",
      disabled: false,
      size: "md",
      loading: false,
      texture: false,
    },
    compoundVariants: [
      {
        intent: "primary",
        texture: true,
        className: "shadow-input shadow-accent/50 bg-gradient-to-b from-accent/80 to-accent",
      },
      {
        intent: "primary",
        texture: true,
        loading: false,
        disabled: false,
        // className:
      },
      {
        intent: "primary",
        texture: false,
        className: "bg-accent",
      },
      {
        intent: "link",
        texture: false,
        className: "hover:underline underline-offset-4",
      },
      {
        intent: "primary",
        texture: false,
        loading: false,
        disabled: false,
        className: "hover:bg-accent/80",
      },
      {
        intent: "secondary",
        texture: true,
        className:
          "bg-base shadow-input shadow-sm shadow-surface border bg-gradient-to-b from-surface/20 to-surface/10",
      },
      {
        intent: "secondary",
        texture: true,
        loading: false,
        disabled: false,
        className: "",
      },
      {
        intent: "secondary",
        texture: false,
        className:
          "border bg-surface hover:bg-surface-muted transition-color translate-y-0 shadow-sm",
      },
      {
        intent: "secondary",
        texture: false,
        loading: false,
        disabled: false,
        className: "",
        // className: "hover:bg-surface",
      },
    ],
  }
);

interface ButtonInnerProps {
  icon?: ReactNode;
  iconRight?: boolean;
  children?: ReactNode;
  label?: string | ReactNode;
  size?: string;
  disabled?: boolean;
  loading?: boolean;
  hotkey?: string;
  external?: boolean;
}

const ButtonInner: FC<ButtonInnerProps & VariantProps<typeof ButtonStyles>> = ({
  iconRight,
  icon,
  children,
  label,
  size = "md",
  disabled = false,
  loading = false,
  hotkey,
}) => {
  return (
    <>
      {icon && !iconRight && (
        <div
          className={cn("relative opacity-80 transition-all", {
            "pr-3": ["md", "lg"].includes(size),
            "pr-2": ["xs", "sm", "subtle"].includes(size),
            "transition-transform  group-hover:animate-pulse group-hover:opacity-100": !disabled,
          })}
        >
          {icon}
        </div>
      )}
      <div className="relative max-w-xs truncate">{label || children}</div>
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{
              opacity: 0,
              width: 0,
            }}
            animate={{
              opacity: 1,
              width: "auto",
            }}
            exit={{
              opacity: 0,
              width: 0,
            }}
            transition={{
              durataion: 10,
            }}
          >
            <div className="relative pl-2">
              <ImSpinner3 className="animate-spin" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {icon && iconRight && !loading && (
          <div
            className={cn("relative opacity-50 transition-all", {
              "pl-3": ["md", "lg"].includes(size),
              "pl-2": ["xs", "sm", "subtle"].includes(size),
              "transition-transform  group-hover:animate-pulse group-hover:opacity-100": !disabled,
            })}
          >
            {icon}
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {hotkey && !loading && (
          <motion.div
            initial={{
              opacity: 0,
              width: 0,
            }}
            animate={{
              opacity: 1,
              width: "auto",
            }}
            exit={{
              opacity: 0,
              width: 0,
            }}
            transition={{
              durataion: 10,
            }}
          >
            <div className="relative pl-2">
              <Keyboard>{hotkey}</Keyboard>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export const SubmitButton = (props: ButtonProps) => (
  <Button
    hotkey="command+enter"
    hotkeyOptions={{
      enableOnContentEditable: true,
      enableOnFormTags: true,
    }}
    type="submit"
    {...props}
  />
);

interface ButtonSpecificProps {
  className?: string;
  label?: string | ReactNode;
  children?: ReactNode;
  icon?: ReactNode;
  iconRight?: boolean;
  external?: boolean;
}

export type ButtonProps = ButtonSpecificProps &
  Omit<ButtonWrapperProps, "children" | "className"> &
  VariantProps<typeof ButtonStyles>;

export const Button = ({
  className,
  icon,
  iconRight,
  children,
  label,
  size = "md",
  intent = "secondary",
  block = false,
  mobileBlock = false,
  external = false,
  ...otherProps
}: ButtonProps): ReactNode => {
  if (external) {
    iconRight = true;
    icon = <BsArrowRight className="-rotate-45" />;
    otherProps.newTab = true;
  }

  if (intent === "icon") {
    const tooltip = (children || label) as string;
    return (
      <ButtonWrapper {...otherProps}>
        {({ disabled, loading }) => (
          <Tooltip tooltip={tooltip}>
            <div
              className={cn(
                "circle-8 opacity-70 transition-all hover:border hover:bg-black/10 hover:opacity-100",
                {
                  "pointer-events-none": disabled || loading,
                  "circle-6 text-sm": size === "sm",
                  "circle-4 text-xs": size === "xs",
                },
                className
              )}
            >
              {!loading && (
                <div
                  className={cn({
                    "opacity-50": disabled,
                  })}
                >
                  {icon!}
                  <div className="sr-only">{tooltip}</div>
                </div>
              )}
              {loading && (
                <div className="relative">
                  <ImSpinner3 className="animate-spin" />
                </div>
              )}
            </div>
          </Tooltip>
        )}
      </ButtonWrapper>
    );
  }

  return (
    <ButtonWrapper
      {...otherProps}
      mobileBlock={mobileBlock}
      className={(renderProps) => {
        return cn(
          ButtonStyles({
            size,
            intent,
            block,
            mobileBlock,
            ...renderProps,
          }),
          className
        );
      }}
    >
      {({ disabled, loading, hotkey }) => (
        <ButtonInner
          icon={icon}
          label={label}
          size={size ?? undefined}
          disabled={disabled}
          loading={loading}
          hotkey={hotkey}
          iconRight={iconRight}
          intent={intent}
        >
          {children}
        </ButtonInner>
      )}
    </ButtonWrapper>
  );
};

export default Button;
