import type { ReactNode } from "react";
import ReactMarkdown from "react-markdown";

interface Props {
  className?: string;
  children?: string | null | ReactNode;
}

const MarkdownString = ({ children, className }: Props) => {
  if (!children) {
    return "";
  }

  if (typeof children !== "string") {
    return <>{children}</>;
  }
  return (
    <ReactMarkdown
      className={className}
      components={{
        blockquote: ({ children }) => {
          return (
            <div className="not-prose border-l-2 border-accent bg-surface-muted p-4">
              {children}
            </div>
          );
        },

        pre: ({ children }) => {
          return <>{children}</>;
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownString;
