import { useRef } from "react";
import {
  useHotkeys as useHotkeysPrimative,
  type HotkeyCallback,
  type Keys,
} from "react-hotkeys-hook";

export interface HotkeyOptions {
  enabled?: boolean;
  enableOnFormTags?: any[] | boolean;
  enableOnContentEditable?: boolean;
}

export const useHotkeys = (keys: Keys, callback: HotkeyCallback, options?: HotkeyOptions) => {
  const { enabled, enableOnFormTags, enableOnContentEditable } = options || {};

  const keysToReplace: Record<string, string> = {
    command: "meta",
  };

  let keysWithReplacements = keys;

  if (typeof keys === "string") {
    keysWithReplacements = keys
      .split("+")
      .map((key) => {
        return keysToReplace[key] || key;
      })
      .join("+");
  }

  // Contains multiple keys
  return useHotkeysPrimative(keysWithReplacements, callback, {
    enabled,
    preventDefault: true,
    enableOnContentEditable,
    enableOnFormTags,
  });
};

/**
 * Hooks up a hotkey to hotkey so that when the hotkey is pressed, the button is clicked. Make sure
 * to pass the ref into the button you want to use it on
 */

export const useButtonHotkeys = (hotkey?: string, options?: HotkeyOptions) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useHotkeys(
    hotkey ?? "???",
    () => {
      buttonRef.current?.click();
      linkRef.current?.click();
    },
    {
      enabled: !!hotkey,
      ...options,
    }
  );

  return { buttonRef, linkRef };
};
