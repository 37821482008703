import { type MouseEvent, type ReactNode } from "react";

import MutationForm from "../../forms/components/core/MutationForm.js";
import { type FormMutationState } from "../../forms/hooks/useFormMutation.js";
import { useFormSubmitting } from "../../forms/hooks/useFormSubmitting.js";
import type { HotkeyOptions } from "../../hooks/useHotkeys.js";
import { useButtonHotkeys } from "../../hooks/useHotkeys.js";
import Link from "../Link/Link.js";

interface ButtonWrapperRenderProps {
  isSubmitButton: boolean;
  loading: boolean;
  disabled: boolean;
  hotkey?: string;
}

export interface ButtonWrapperProps {
  className?: (props: ButtonWrapperRenderProps) => string;
  children: (props: ButtonWrapperRenderProps) => ReactNode;
  loading?: boolean;
  onClick?: (e: MouseEvent) => void;
  to?: string;
  /**
   * If true then it will post to the to If false then it will use a normal link
   */
  postTo?: boolean;

  downloadLink?: string;
  type?: "button" | "submit";
  disabled?: boolean;

  name?: string;
  value?: string;

  id?: string;

  // New tab
  newTab?: boolean;
  /**
   * Pass in a mutation to wrap the button in a form with the mutation passed in
   */
  mutation?: FormMutationState;
  /**
   * If this button is a submit button, this is the actionId that will be submitted
   */
  actionId?: string;

  /**
   * Useful if you want to submit a form that is not the closest form
   */
  form?: string;

  /**
   * If included this button will include a keyboard shortcut. This hooks it up as well as shows the
   * shortcut on the button
   */
  hotkey?: string;
  hotkeyOptions?: HotkeyOptions;

  mobileBlock?: boolean;
}

const ButtonWrapper = ({
  children,
  className,
  to,
  downloadLink,
  newTab,
  actionId,
  type = "button",
  mutation,
  disabled,
  loading,
  hotkey,
  hotkeyOptions,
  onClick,
  postTo,
  form,
  name,
  value,
  mobileBlock,
}: ButtonWrapperProps): ReactNode => {
  const isSubmitButton = type === "submit" || actionId !== undefined;
  const isSubmittingForm = useFormSubmitting(actionId);

  let isMutating = mutation && mutation?.fetcher.state !== "idle";

  /**
   * If this is a submit button and we have a name, check if the value is the same as the submitting
   * value This prevents an issue where you have arrays of forms with the same ID. You only want to
   * show loading on the form that is actually submitting
   */
  if (isMutating && name) {
    const submittingValue = mutation?.fetcher.formData?.get(name);
    if (submittingValue && submittingValue !== value) {
      isMutating = false;
    }
  }

  const showLoading = loading ?? (isSubmittingForm && isSubmitButton && !to) ?? isMutating;

  const showDisabled = (isSubmittingForm && isSubmitButton) || disabled || isMutating;

  const renderProps = {
    isSubmitButton,
    loading: showLoading!,
    disabled: showDisabled!,
    hotkey,
  };

  const { buttonRef, linkRef } = useButtonHotkeys(hotkey, hotkeyOptions);

  const classNameString = className?.(renderProps) ?? "";

  if (to) {
    return (
      <Link
        to={to}
        target={newTab ? "_blank" : undefined}
        ref={linkRef}
        className={classNameString}
        postTo={postTo}
      >
        {children(renderProps)}
      </Link>
    );
  } else if (downloadLink) {
    return (
      <a href={downloadLink} download={true} className={classNameString}>
        {children(renderProps)}
      </a>
    );
  } else if (mutation) {
    return (
      <MutationForm {...mutation} toastOnlyErrors className={mobileBlock ? "w-full md:w-auto" : ""}>
        <button
          name={name}
          value={value}
          disabled={showDisabled}
          type="submit"
          ref={buttonRef}
          className={classNameString}
        >
          {children(renderProps)}
        </button>
      </MutationForm>
    );
  } else {
    return (
      <button
        ref={buttonRef}
        disabled={showDisabled}
        name={actionId ? "__actionId" : undefined}
        value={actionId}
        type={isSubmitButton ? "submit" : type}
        className={classNameString}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(e);
        }}
        form={form}
      >
        {children(renderProps)}
      </button>
    );
  }
};

export default ButtonWrapper;
