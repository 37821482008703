import { Children, Fragment, type ReactNode } from "react";

import cn from "../../util/cn.js";
import { GenericStyles, type GenericStylesProps } from "./genericStyles.js";

interface Props extends GenericStylesProps {
  children: ReactNode;
  divider?: ReactNode | boolean;
  className?: string;
  gapNone?: boolean;
  gapXs?: boolean;
  gapSm?: boolean;
  gapMd?: boolean;
  gapLg?: boolean;

  alignXStart?: boolean;
  alignXCenter?: boolean;
  alignXEnd?: boolean;
  alignXStretch?: boolean;
  alignXBetween?: boolean;

  alignYTop?: boolean;
  alignYCenter?: boolean;
  alignYBottom?: boolean;
  alignYStretch?: boolean;
  alignYBetween?: boolean;

  stackOnMobile?: boolean;
}

const HStack = ({
  className,
  children,
  gapNone,
  gapXs,
  gapSm,
  gapMd,
  gapLg,
  alignXStart,
  alignXCenter,
  alignXEnd,
  alignXStretch,
  alignXBetween,
  alignYTop,
  alignYCenter,
  alignYBottom,
  alignYStretch,
  alignYBetween,
  stackOnMobile,
  divider,
  ...styles
}: Props) => {
  const childrenArray = Children.toArray(children);
  return (
    <div
      className={cn(
        "items-center gap-x-4 gap-y-1",
        "flex",
        {
          "gap-x-0": gapNone,
          "gap-x-1": gapXs,
          "gap-x-2": gapSm,
          "gap-x-4": gapMd,
          "gap-x-8": gapLg,
        },
        {
          "justify-start": alignXStart,
          "justify-center": alignXCenter,
          "justify-end": alignXEnd,
          "justify-stretch": alignXStretch,
          "justify-between": alignXBetween,
        },
        {
          "items-start": alignYTop,
          "items-center": alignYCenter,
          "items-end": alignYBottom,
          "items-stretch": alignYStretch,
          "items-between": alignYBetween,
        },
        {
          "flex-col md:flex-row": stackOnMobile,
        },
        GenericStyles(styles),
        className
      )}
    >
      {childrenArray.map((child, index) => {
        return (
          <Fragment key={index}>
            {child}
            {index !== childrenArray.length - 1 && divider && (
              <div className="flex-shrink-0">{divider}</div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default HStack;
