import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { type ComponentProps, type ReactNode } from "react";
import { FaCheck, FaExclamationTriangle, FaInfo, FaTimes } from "react-icons/fa";

import cn from "../../util/cn.js";
import Button from "../Button/Button.js";
import HStack from "../Stack/HStack.js";
import MarkdownString from "../Typography/MarkdownString.js";

interface Props extends VariantProps<typeof CalloutStyles> {
  className?: string;
  description?: ReactNode;
  cta?: ComponentProps<typeof Button>;
  secondaryCta?: ComponentProps<typeof Button>;
  title?: string;
  icon?: ReactNode;
  inline?: boolean;
}

const CalloutStyles = cva(["flex-col md:flex-row flex md:items-center gap-6 bg-surface"], {
  variants: {
    intent: {
      info: "",
      success: "",
      error: "bg-error/50 border-error text-error",
      warning: "bg-yellow-500/10 border-yellow-500 text-yellow-900",
    },
    borderless: {
      false: "border rounded-lg",
      undefined: "border rounded-lg",
    },
    inline: {
      true: "px-4 py-2",
      false: "px-6 py-4",
      undefined: "px-6 py-4",
    },
  },
});

const CalloutIconStyles = cva([" flex-none "], {
  variants: {
    intent: {
      info: "bg-accent text-accent-inverse",
      success: "bg-green-500",
      error: "bg-error-accent text-accent-inverse",
      warning: "bg-yellow-500 text-yellow-900",
    },
    inline: {
      true: "circle-6 text-sm",
      false: "circle-8",
      undefined: "circle-8",
    },
  },
  defaultVariants: {
    intent: "info",
  },
});

const CalloutTitleStyle = cva("text-md font-medium", {
  variants: {
    intent: {
      info: "",
      success: "text-green-900",
      error: "text-error",
      warning: "text-yellow-900",
    },
    borderless: {},
  },
});

const CalloutParagraphStyle = cva("", {
  variants: {
    intent: {
      warning: "text-yellow-900/70",
      error: "text-red-900",
    },
    borderless: {},
  },
});

const Callout = ({
  className,
  description,
  cta,
  secondaryCta,
  title,
  icon,
  ...cvaProps
}: Props) => {
  const { intent = "info", inline = false } = cvaProps;

  const IconNode = (
    <div className={CalloutIconStyles(cvaProps)}>
      {icon}
      {!icon && (
        <>
          {intent === "info" && <FaInfo />}
          {intent === "warning" && <FaExclamationTriangle />}
          {intent === "error" && <FaTimes />}
          {intent === "success" && <FaCheck />}
        </>
      )}
    </div>
  );

  if (inline) {
    return (
      <div className={cn(CalloutStyles(cvaProps), className)}>
        <HStack>
          {IconNode}
          {description && (
            <MarkdownString className={CalloutParagraphStyle(cvaProps as any)}>
              {description}
            </MarkdownString>
          )}
          {cta && <Button className="px-0 underline" intent="link" size="sm" {...cta} />}
          {secondaryCta && (
            <Button className="px-0 underline" intent="link" size="sm" {...secondaryCta} />
          )}
        </HStack>
      </div>
    );
  }
  return (
    <div className={cn(CalloutStyles(cvaProps), className)}>
      {IconNode}
      <div className="flex flex-grow flex-col gap-1">
        {title && <h3 className={CalloutTitleStyle(cvaProps as any)}>{title}</h3>}
        {description && (
          <MarkdownString className={CalloutParagraphStyle(cvaProps as any)}>
            {description}
          </MarkdownString>
        )}
        {(cta || secondaryCta) && (
          <HStack>
            {cta && <Button className="px-0 underline underline-offset-4" intent="link" {...cta} />}
            {secondaryCta && (
              <Button
                className="px-0 underline underline-offset-4"
                intent="link"
                {...secondaryCta}
              />
            )}
          </HStack>
        )}
      </div>
    </div>
  );
};

export default Callout;
