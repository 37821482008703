import { Dialog as DialogPrimative, Transition } from "@headlessui/react";
import { Fragment, type ReactNode } from "react";

import MutationForm from "../../forms/components/core/MutationForm.js";
import { type FormMutationState } from "../../forms/hooks/useFormMutation.js";
import type { BoolState } from "../../hooks/useBool.js";
import cn from "../../util/cn.js";
import type { ButtonProps } from "../Button/Button.js";
import Button from "../Button/Button.js";

interface Props {
  title?: string;
  description?: string;

  // Button or something on the right
  accessory?: ReactNode;

  bool: BoolState;

  /**
   * Success handler. If this is a mutation you should wait until the mutation finishes and leave
   * this undefiend
   */
  onSuccess?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  icon?: ReactNode;

  cancelCta?: ButtonProps;
  successCta?: ButtonProps;

  mutation?: FormMutationState;

  /**
   * Setting this to true will set the success button to submit and disable both buttons
   */
  submitting?: boolean;

  size?: "sm" | "md" | "lg" | "xl" | "2xl";

  /**
   * If true, the dialog will be a fixed distance from the top instead of centered
   */
  pinToTop?: boolean;

  /**
   * Classname for the div that wraps the children
   */
  childrenContainerClassName?: string;

  /**
   * If false, the dialog will not close on click away. Will throw an error if passed alongside
   * cancelCta
   */
  closeable?: boolean;
}

export function Dialog({
  title,
  description,
  bool,
  onSuccess,
  icon,
  onCancel,
  submitting,
  children,
  cancelCta,
  successCta,
  mutation,
  size = "md",
  pinToTop = false,
  childrenContainerClassName,
  closeable = true,
}: Props) {
  if (closeable === false && cancelCta) {
    throw new Error("Cannot pass closeable=false and cancelCta");
  }

  const panel = (
    <div>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div>
            {icon && (
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-accent text-accent-inverse sm:mx-0 sm:mr-4 sm:h-10 sm:w-10">
                {icon}
              </div>
            )}
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              {title && (
                <DialogPrimative.Title as="h3" className="text-lg font-medium leading-6">
                  {title}
                </DialogPrimative.Title>
              )}
              {description && (
                <DialogPrimative.Description as="p" className="text-sm text-secondary">
                  {description}
                </DialogPrimative.Description>
              )}
            </div>
          </div>
        </div>
        <div className={childrenContainerClassName || ""}>{children}</div>
      </div>

      {/* TODO: Mobile styling not right */}
      {(successCta || cancelCta) && (
        <div className="border-t bg-surface px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          {successCta && (
            <Button
              hotkey="command+enter"
              onClick={() => onSuccess?.()}
              className="sm:ml-3"
              loading={submitting ?? mutation?.mutating}
              disabled={submitting ?? mutation?.mutating}
              type={mutation ? "submit" : "button"}
              intent="primary"
              hotkeyOptions={{
                enableOnFormTags: true,
              }}
              {...successCta}
            ></Button>
          )}
          {cancelCta && (
            <Button
              hotkey="Escape"
              intent="secondary"
              onClick={() => {
                onCancel?.() ?? bool.hide();
              }}
              disabled={submitting || mutation?.mutating}
              {...cancelCta}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <Transition.Root show={bool.value} as={Fragment}>
      <DialogPrimative
        as="div"
        className="relative z-50"
        onClose={() => {
          (closeable ?? true) && bool.hide();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={cn("flex min-h-full items-end justify-center p-4 text-center  sm:p-0", {
              "sm:items-center": !pinToTop,
              "mt-12 sm:items-start": pinToTop,
            })}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPrimative.Panel
                className={cn(
                  "relative transform overflow-hidden rounded-lg border border-base bg-base text-left shadow-xl transition-all",
                  {
                    "sm:my-4 sm:w-full sm:max-w-md": size === "sm",
                    "sm:my-8 sm:w-full sm:max-w-lg": size === "md",
                    "sm:my-10 sm:w-full sm:max-w-2xl": size === "lg",
                    "sm:my-12 sm:w-full sm:max-w-4xl": size === "xl",
                    "sm:my-12 sm:w-full sm:max-w-6xl": size === "2xl",
                  }
                )}
              >
                {mutation ? <MutationForm {...mutation}>{panel}</MutationForm> : panel}
              </DialogPrimative.Panel>
            </Transition.Child>
          </div>
        </div>
      </DialogPrimative>
    </Transition.Root>
  );
}
