import { useCallback, useState } from "react";

export interface BoolState {
  value: boolean;
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
  setValue: (value: boolean) => void;
  show: () => void;
  hide: () => void;
}

/**
 * UseBool is a hook that returns a boolean value and functions to toggle, set to true, and set to
 * false.
 *
 * @param initialValue
 * @returns
 */
export const useBool = (initialValue: boolean = false): BoolState => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => setValue((v) => !v), []);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const _setValue = useCallback((value: boolean) => setValue(value), []);
  const show = useCallback(() => setValue(true), []);
  const hide = useCallback(() => setValue(false), []);

  return { value, toggle, setTrue, setFalse, setValue: _setValue, show, hide };
};
