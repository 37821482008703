import type { ReactNode } from "react";

import cn from "../../util/cn.js";
import { LINE_LIMIT_CLASSES, type LineLimit } from "../../util/LineLimit.js";

interface Props {
  className?: string;
  children: ReactNode;
  lineLimit?: LineLimit;
}

const Paragraph = ({ className, children, lineLimit }: Props) => {
  return (
    <div
      className={cn(
        "text-sm leading-5 text-secondary dark:text-white/80",
        className,
        lineLimit ? LINE_LIMIT_CLASSES[lineLimit] : undefined
      )}
    >
      {children}
    </div>
  );
};

export default Paragraph;
