import type { ReactNode } from "react";
import {
  BsAlt,
  BsArrowDownSquare,
  BsArrowLeftSquare,
  BsArrowReturnLeft,
  BsArrowRightSquare,
  BsArrowUpSquare,
  BsBackspace,
  BsCommand,
  BsShift,
} from "react-icons/bs";
import { MdKeyboardTab } from "react-icons/md";

import cn from "../../util/cn.js";

export interface Props {
  className?: string;
  children: string;
}

// Keys to Map to Icons
const keyMap: Record<string, ReactNode> = {
  command: <BsCommand />,
  meta: <BsCommand />,
  return: <BsArrowReturnLeft />,
  enter: <BsArrowReturnLeft />,
  shift: <BsShift />,
  alt: <BsAlt />,
  arrowup: <BsArrowUpSquare />,
  arrowdown: <BsArrowDownSquare />,
  arrowleft: <BsArrowLeftSquare />,
  arrowright: <BsArrowRightSquare />,
  backspace: <BsBackspace />,
  tab: <MdKeyboardTab />,
  escape: "ESC",
};

const Keyboard = ({ className, children }: Props) => (
  <kbd
    className={cn(
      className,
      "bg-text/10 flex h-5 items-center gap-1 rounded border border-text/20 bg-base/20 px-2 py-1 font-mono text-xs font-bold"
    )}
  >
    {children.split("+").map((char) => {
      if (char.toLowerCase() in keyMap) {
        return (
          <span key={char}>
            {keyMap[char.toLowerCase()]} <span className="sr-only">{char}</span>
          </span>
        );
      } else {
        return (
          <span key={char} style={{ fontSize: "0.8rem" }}>
            {char.toUpperCase()}
          </span>
        );
      }
    })}
  </kbd>
);

export default Keyboard;
